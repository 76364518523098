import React from "react";
import PageLayout from "../components/Shared/PageLayout";
import { EDPageLoader } from "@trailblazer-game/ed-webkit";
import dynamic from "next/dynamic";

const LoginMainLayout = dynamic(() => import("../components/Pages/Login/Public/Login_MainLayout"), {
    suspense: false,
    ssr: false,
    loading: () => <EDPageLoader in={true}/>,
})

export default function LoginPage() {
    return <PageLayout backgroundImage={"eternaldragons_website_illustration_02_sm.jpg"}
                       backgroundColor={"rgba(0,0,0,0.5)"}
                       justifyContent={"flex-start"}>
        <LoginMainLayout/>
    </PageLayout>;
}

LoginPage.metaData = {
    title: 'Eternal Dragons - Player Login Portal',
    description: 'Access your Eternal Dragons account and dive into the world of dragon warfare. Log in to command legendary creatures and conquer your foes. Join the battle now!'
}